import React from "react";
import styled, { keyframes } from "styled-components";

export const LoadingThumbnailPlaceholder: React.FC = () => (
  <LoadingPlaceHodlerWrapper>
    {[...Array(3)].map((_, index) => (
      <LoadingPlaceHolderColumnWrapper key={index}>
        {[...Array(3)].map((_, index) => (
          <LoadingPlaceHolder key={index} />
        ))}
      </LoadingPlaceHolderColumnWrapper>
    ))}
  </LoadingPlaceHodlerWrapper>
);

export const LoadingProductPlaceholder: React.FC = () => (
  <LoadingPlaceHolderColumnWrapper>
    {[...Array(3)].map((_, index) => (
      <LoadingPlaceHolder key={index} />
    ))}
  </LoadingPlaceHolderColumnWrapper>
);

const LoadingPlaceHodlerWrapper = styled.div`
  display: flex;
  padding-bottom: 80px;
  align-items: flex-start;
  gap: var(--column-gap, 102px);
  align-self: stretch;
`;

const LoadingPlaceHolderColumnWrapper = styled.div<
  React.AllHTMLAttributes<HTMLDivElement>
>`
  display: flex;
  width: var(--3-column, 400px);
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  margin-top: 20px;
`;

const fadeInOut = keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
`;

const LoadingPlaceHolder = styled.div`
  height: 40px;
  align-self: stretch;
  border-radius: 4px;
  background: linear-gradient(
    113deg,
    #e7e7e7 -14.67%,
    #f2f2f2 54.92%,
    #e8e8e8 126.88%
  );
  opacity: 1;
  animation: ${fadeInOut} 2s infinite;
`;
