import React from "react";
import styled from "styled-components";
import Rating from "react-rating";

import { Overview } from "models/pages/products";
import { StarFilled, StarGray } from "../../components/icon/Star";
import TagGroup from "./TagGroup";
import {
  LoadingProductPlaceholder,
  LoadingThumbnailPlaceholder,
} from "./LoadingPlaceHolders";

interface OverviewSectionProps {
  overviews: Overview[];
  isLoadingThumbnail: boolean;
}

const OverviewSection: React.FC<OverviewSectionProps> = ({
  overviews,
  isLoadingThumbnail,
}) => {
  return (
    <OverviewSectionWrapper>
      {isLoadingThumbnail ? (
        <LoadingThumbnailPlaceholder />
      ) : (
        overviews.map((overview, index) => (
          <OverviewWrapper key={index}>
            {overview.isLoading ? (
              <LoadingProductPlaceholder />
            ) : (
              <>
                <ScoreAndRatingWrapper>
                  <Score>{overview.rating}</Score>
                  <StarsWrapper>
                    <Rating
                      initialRating={overview.rating}
                      readonly
                      emptySymbol={<StarGray />}
                      fullSymbol={<StarFilled />}
                    />
                  </StarsWrapper>
                </ScoreAndRatingWrapper>
                <TagAndContentWrapper>
                  {overview.content.highlights &&
                    overview.content.highlights.length > 0 && (
                      <OverviewTagWrapper>
                        <TagGroup tags={overview.content.highlights}></TagGroup>
                      </OverviewTagWrapper>
                    )}
                  <OverviewContent>
                    {overview.content.textcontent}
                  </OverviewContent>
                </TagAndContentWrapper>
              </>
            )}
          </OverviewWrapper>
        ))
      )}
    </OverviewSectionWrapper>
  );
};

export default OverviewSection;

const OverviewSectionWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--column-gap, 102px);
`;

const OverviewWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  flex: 1 0 0;
`;

const OverviewTagWrapper = styled("div")`
  width: 100%;
  margin-bottom: 20px;
`;

const OverviewContent = styled("div")`
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  align-self: stretch;
`;

const ScoreAndRatingWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Score = styled.div`
  height: 65px;
  color: #0d0319;
  font-family: "Noto Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.92px;
`;

const StarsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 6px;
`;

const TagAndContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;
