import React from "react";
import styled from "styled-components";
import { CommonSectionInfo } from "models/pages/products";
import TagGroup from "./TagGroup";
import Markdown from "react-markdown";
import {
  LoadingProductPlaceholder,
  LoadingThumbnailPlaceholder,
} from "./LoadingPlaceHolders";

interface ReviewSectionProps {
  reviews: CommonSectionInfo[];
  isLoadingThumbnail: boolean;
}

const ReviewSection: React.FC<ReviewSectionProps> = ({
  reviews,
  isLoadingThumbnail,
}) => (
  <ReviewSectionWrapper>
    {isLoadingThumbnail ? (
      <LoadingThumbnailPlaceholder />
    ) : (
      reviews.map((review, index) => (
        <ReviewWrapper key={index}>
          {review.isLoading ? (
            <LoadingProductPlaceholder />
          ) : (
            <>
              {review.highlights.length > 0 && (
                <ReviewTagWrapper>
                  <TagGroup tags={review.highlights}></TagGroup>
                </ReviewTagWrapper>
              )}
              <ReviewContent>
                <Markdown>{review.content}</Markdown>
              </ReviewContent>
            </>
          )}
        </ReviewWrapper>
      ))
    )}
  </ReviewSectionWrapper>
);

export default ReviewSection;

const ReviewSectionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--column-gap, 102px);
  align-self: stretch;
`;

const ReviewWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

const ReviewTagWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const ReviewContent = styled.div`
  width: 100%;
`;
