export interface UrlConfig {
  API_SUBDOMAIN: string; // e.g. 'api' | 'api-staging', passed in through config
  API_URL: string; // derived
  API_OVERRIDE?: string; // e.g. localhost:3000, for testing locally
}

export function getEnvFromHostname(): 'production' | 'staging' | 'local' {
  if (typeof window === 'undefined') {
    return 'local';
  }
  const { hostname } = window.location;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const {searchParams} = new URL(document.location);
  if (/-staging\./.test(hostname) || /\.diff-preview\./.test(hostname) || searchParams.get('staging')) {
    return 'staging';
  }
  if (hostname === 'localhost' || hostname === '192.168.99.100' || hostname === '127.0.0.1') {
    return 'local';
  }
  return 'production';
}

export function createConfigWithDerivedApiUrl<T extends UrlConfig>(partialConfig: Omit<T, 'API_URL'>) {
  return {
    ...partialConfig,
    API_URL: buildAPIURL(partialConfig.API_SUBDOMAIN, partialConfig.API_OVERRIDE),
  };
}

export function buildAPIURL(subdomain: string, urlOverride?: string): string {
  if (urlOverride) {
    return urlOverride;
  }
  const { hostname } = window.location;
  if (hostname === 'localhost' || hostname === '192.168.99.100' || /\.diff-preview\./.test(hostname)) {
    return `https://${subdomain}.shop4u.ai`;
  }
  // replace first subdomain with prefix
  return `https://${hostname.replace(/^[^.]*/, subdomain)}`;
}

