import React, { useEffect, useState } from "react";
import Dot from '../../components/icon/Dot';
import LeftArrow from '../../components/icon/LeftArrow';
import RightArrow from '../../components/icon/RightArrow';
import BackIcon from "../../components/icon/BackIcon";
import { Link, useHistory } from 'react-router-dom';
import { SessionProductsData } from '../../models/services/product';
import { listSessionProducts, exchangeSessionIdBySnapshotName } from '../../services/suggestions';
import { range, capFilter } from '../../common/list';
import styled from "styled-components";

const DEFAULT_LIST_SESSION_PRODUCTS_PAGE_SIZE = 10;
const DEFAULT_PAGINATION_PAGE_INTERVAL = 3;

const SuggestionSectionItem = (props: { link: string, productNames: string[] }) => {
    const history = useHistory();
    return <div onClick={() => {
        let link = props.link;
        if (props.link.startsWith('https://www.shop4u.ai')) {
          link = props.link.replace('https://www.shop4u.ai', '');
        }
        history.push(link);
      }}>
        <SuggestionSectionItemWrapper>
            {props.productNames.map((productName, index) => <><SuggestionSectionItemText>{productName}</SuggestionSectionItemText>{index < props.productNames.length - 1 ? '·' : ''}</>)}
        </SuggestionSectionItemWrapper>
    </div>
}

const SuggestionSection = (props: { title: string, sessionProducts: SessionProductsData }) => <SuggestionSectionWrapper>
    <SuggestionSectionTitle>{props.title}</SuggestionSectionTitle>
    <SuggestionSectionItemsWrapper>
        {Object.keys(props.sessionProducts).map(sessionProductKey => <SuggestionSectionItem link={sessionProductKey} productNames={props.sessionProducts[sessionProductKey]} />)}
    </SuggestionSectionItemsWrapper>
</SuggestionSectionWrapper>

const SuggestionPaginationFooter = (props: { pageNumber: number, totalPages: number, onPageChange: (pageNumber: number) => Promise<void> }) => {
    const { pageNumber, totalPages, onPageChange } = props;
    const canGoToPreviousPage = pageNumber > 1;
    const canGoToNextPage = pageNumber < totalPages;
    const middlePages = range(pageNumber - Math.ceil(DEFAULT_PAGINATION_PAGE_INTERVAL / 2), pageNumber + Math.ceil(DEFAULT_PAGINATION_PAGE_INTERVAL / 2)).filter(capFilter(1, totalPages));
    const renderPageItem = (num: number) => pageNumber === num ? <PageItemWrapper>{num}</PageItemWrapper> : <div style={{cursor: 'pointer', color: 'purple'}} onClick={() => onPageChange(num)}><PageItemWrapper>{num}</PageItemWrapper></div>;

    const paginationLeftPart =
        middlePages[0] <= 1 ? '' : <>{renderPageItem(1)}...</>;
    const paginationMiddlePart = <>
        {middlePages.map(pageNm => renderPageItem(pageNm))}
    </>;
    const paginationRightPart =
        pageNumber >= totalPages - DEFAULT_PAGINATION_PAGE_INTERVAL ? '' : <>...{renderPageItem(totalPages)}</>;
    return <SuggestionPaginationWrapper>
        <PageItemBorderedWrapper>
            <LeftArrow style={{color: canGoToPreviousPage ? "#8319F5" : "grey", cursor: canGoToPreviousPage ? "pointer" : "not-allowed"}} onClick={() => canGoToPreviousPage && onPageChange(pageNumber - 1)}/>
        </PageItemBorderedWrapper>
        {paginationLeftPart}
        {paginationMiddlePart}
        {paginationRightPart}
        <PageItemBorderedWrapper>
            <RightArrow style={{color: canGoToNextPage ? "#8319F5" : "grey", cursor: canGoToNextPage ? "pointer" : "not-allowed"}} onClick={() => canGoToNextPage && onPageChange(pageNumber + 1)}/>
        </PageItemBorderedWrapper>
    </SuggestionPaginationWrapper>
}

export const SuggestionsPage = () => {
    const history = useHistory();
    const [sessionProducts, setSessionProducts] = useState<SessionProductsData>({});
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    useEffect(() => {
        listSessionProducts(pageNumber, DEFAULT_LIST_SESSION_PRODUCTS_PAGE_SIZE).then(sessionProducts => {
            setTotalRecords(sessionProducts.total);
            setSessionProducts(sessionProducts.results);
        });
    }, [pageNumber]);
    const changePage = async (pageNum: number) => {
        const remoteSessionProductsResponse = await listSessionProducts(pageNum);
        setSessionProducts(remoteSessionProductsResponse.results);
        setPageNumber(pageNum);
        setTotalRecords(remoteSessionProductsResponse.total);
    }
    return <>
        <SuggestionPageWrapper>
            <NavWrapper>
                <IconWrapper>
                    <NavName onClick={() => {
                        history.push('/');
                    }}>
                        <BackIcon />
                    </NavName>
                </IconWrapper>
            </NavWrapper>
            <TitleTextWrapper>Compare these products</TitleTextWrapper>
            <SuggestionsContentWrapper>
                <SuggestionDisplayContentWrapper>
                    <SuggestionSection title="🔥Most popular items people compare..." sessionProducts={sessionProducts} />
                </SuggestionDisplayContentWrapper>
                {!!totalRecords && <SuggestionPaginationFooter pageNumber={pageNumber} totalPages={Math.ceil(totalRecords / DEFAULT_LIST_SESSION_PRODUCTS_PAGE_SIZE)} onPageChange={changePage} />}
            </SuggestionsContentWrapper>
        </SuggestionPageWrapper>
    </>
};



const SuggestionSectionItemText = styled.div`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--gray70, #74697B);
    text-align: left;
    text-overflow: ellipsis;
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
`

const SuggestionSectionItemWrapper = styled.div`
    align-items: center;
    gap: 12px;
    border-radius: 200px;
    display: flex;
    cursor: pointer;
`

const SuggestionSectionTitle = styled.div`
    color: var(--black, #0D0319);
    text-align: right;
    font-family: "Noto Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 156.25% */
`

const SuggestionSectionItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--corner_card, 16px);
`

const SuggestionSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--corner_card, 16px);
`;

const SuggestionPaginationWrapper = styled.div`
    display: flex;
    width: 760px;
    padding: 6.837px 0px;
    align-items: center;
    gap: 4px;
`;

const PageItemBorderedWrapper = styled.div`
    display: flex;
    width: 36px;
    height: 36px;
    padding: 8.836px 9px 9.164px 9px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 68.367px;
    border: 1px solid var(--gray50, #A79DAE);
`;

const PageItemWrapper = styled.div`
    display: flex;
    width: 36px;
    height: 36px;
    padding: 8.836px 9px 9.164px 9px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 68.367px;
`;

const SuggestionDisplayContentWrapper = styled.div`
    display: flex;
    width: 760px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex: 1 0 0;
`

const SuggestionsContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
`

const SuggestionPageWrapper = styled.div`
    display: flex;
    width: 1728px;
    padding: 40px 24px;
    flex-direction: column;
    align-items: center;
    gap: 60px;
`;

const TitleTextWrapper = styled.div`
    color: var(--black, #0D0319);
    text-align: center;
    font-family: "Noto Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.44px;
`;


// nav utils
const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const NavName = styled("div")`
  height: 30px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #0d0319;
`;