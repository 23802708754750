import React, { useState } from "react";
import { DropDownMenu, MenuItem} from '../dropdown_menu/DropdownMenu';
import styled from "styled-components";
import { User } from "../../models/user";
import SignOut from "../icon/SignOut";
import AngleDown from "../icon/AngleDownIcon";
import { logout } from '../../services/user';

type ProfileProps = {
  user: User;
};

const DROP_DOWN_ITEMS: MenuItem[] = [
  {
    icon: <SignOut />,
    text: "Sign Out",
    action: logout,
  },
];

const Profile = (props: ProfileProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <ProfileWrapper>
      <ProfileImage src={props.user.profile_image} />
      <DropdownWrapper onClick={() => setShowDropdown(!showDropdown)}>
        <AngleDown />
        <DropDownMenu show={showDropdown} items={DROP_DOWN_ITEMS} />
      </DropdownWrapper>
    </ProfileWrapper>
  );
};

export default Profile;

const ProfileWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ProfileImage = styled("img")`
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: url(<path-to-image>) lightgray 50% / cover no-repeat;
`;

const DropdownWrapper = styled("div")`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;