import React from "react";
import styled from "styled-components";

export type MenuItem = {
  icon: JSX.Element;
  text: string;
  action: () => Promise<void>;
};

type DropDownMenuProps = {
  show: boolean;
  items: MenuItem[];
};

export const DropDownMenu = (props: DropDownMenuProps) => {
  return props.show ? (
    <DropdownMenuWrapper>
      {props.items.map((item) => (
        <DropdownMenuItemWrapper key={item.text} onClick={item.action}>
          <DropdownMenuItemIcon>{item.icon}</DropdownMenuItemIcon>
          <DropdownMenuItemText>{item.text}</DropdownMenuItemText>
        </DropdownMenuItemWrapper>
      ))}
    </DropdownMenuWrapper>
  ) : null;
};



const DropdownMenuWrapper = styled.div`
  display: flex;
  padding: 12px var(--corner_card, 16px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--corner_card, 16px);
  position: absolute;
  right: 16%;
  top: 7%;
  border-radius: 8px;
  background: var(--black, #0d0319);
  box-shadow: 0px 4px 16px 8px rgba(67, 54, 76, 0.12);
`;

const DropdownMenuItemWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DropdownMenuItemIcon = styled.div`
  width: 20px;
  height: 20px;
`;

const DropdownMenuItemText = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--white, #fff);
  text-align: right;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;
