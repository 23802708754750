import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";

import PromptLogo from "components/icon/PromptLogo";
import ProductSuggestions from "./ProductSuggestions";
import { ChatProductInfo } from "models/services/api";

export type LayoutScheme =
  | "question"
  | "response"
  | "productSuggestions"
  | "unknown";

export interface SectionContent {
  content: string | ChatProductInfo[];
  layoutScheme: LayoutScheme;
}

interface PromptSectionProps {
  sectionContent: SectionContent;
  productUrls: string[];
  onAddProduct: () => void;
  sessionId: string;
}

const PromptSection: React.FC<PromptSectionProps> = ({
  sectionContent,
  productUrls,
  onAddProduct,
  sessionId,
}) => {
  return (
    <>
      {sectionContent.layoutScheme === "question" && (
        <QuestionWrapper>{sectionContent.content}</QuestionWrapper>
      )}
      {sectionContent.layoutScheme === "response" && (
        <ResultWrapper>
          <PromptLogoWrapper>
            <PromptLogo />
          </PromptLogoWrapper>
          <ResultContent>
            {typeof sectionContent.content === "string" ? (
              <Markdown>{sectionContent.content}</Markdown>
            ) : (
              sectionContent.content
            )}
          </ResultContent>
        </ResultWrapper>
      )}
      {sectionContent.layoutScheme === "productSuggestions" && (
        <ProductSuggestions
          results={sectionContent.content as ChatProductInfo[]}
          productUrls={productUrls}
          onAddProduct={onAddProduct}
          sessionId={sessionId}
        >
          {sectionContent.content}
        </ProductSuggestions>
      )}
    </>
  );
};

export default PromptSection;

const QuestionWrapper = styled.div`
  a {
    color: #9b59b6;
  }
  display: flex;
  padding: 10px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  background: var(--gray90, #43364c);
  width: 260px;
  color: var(--white, #fff);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  align-self: end;
`;

const ResultWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const PromptLogoWrapper = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  background: var(
    --brand,
    linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%)
  );
`;

const ResultContent = styled.div`
  flex: 1 0 0;
  align-self: stretch;
  color: var(--gray70, #74697b);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;
