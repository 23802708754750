import styled from "styled-components";
import React, { ReactNode, useCallback, useState } from "react";

import ChevronUpIcon from "../../components/icon/ChevronUpIcon";
import FeedbackForm from "./FeedbackForm";
import MsgAlert from "./MsgAlert";
import ThumbsUpIcon from "../../components/icon/ThumbsUpIcon";
import ThumbsDownIcon from "../../components/icon/ThumbsDownIcon";
import { SectionName, sendFeedback } from "../../services/product";

interface ProductCardProps {
  title: string;
  button?: ReactNode;
  size: number;
  sessionId: string;
  sectionName: SectionName | string;
  id: string;
}

const ProductSection: React.FC<ProductCardProps> = ({
  title,
  button,
  children,
  sessionId,
  sectionName,
  id,
}) => {
  const [disableContent, setDisableContent] = useState<boolean>(false);
  const [formShow, setFormShow] = useState<boolean>(false);
  const [choose, setChoose] = useState<number>(0);
  const [msg, setMsgShow] = useState(false);

  const isPredefinedSectionTypes = (name: string): name is SectionName => {
    return [
      "Overview",
      "Buying Options",
      "Specifications",
      "Ratings & Reviews",
    ].includes(name);
  };

  const changeFormShow = (
    choose: number,
    choosePre: number,
    close: boolean
  ) => {
    if (close) {
      setFormShow(!formShow);
      setChoose(0);
      return;
    }
    if (choosePre == 0) {
      setFormShow(!formShow);
    }
    if (choose === -1) {
      if (isPredefinedSectionTypes(sectionName)) {
        sendFeedback(sessionId, sectionName, true);
      }
      openMsgShow();
    } else if (choose === 1) {
      if (isPredefinedSectionTypes(sectionName)) {
        sendFeedback(sessionId, sectionName, false);
      }
      openMsgShow();
    }
    setChoose(choose);
  };
  const openMsgShow = () => {
    setMsgShow(true);
  };
  const closeMsgShow = () => {
    setMsgShow(false);
  };
  const toggleContent = useCallback(() => {
    if (disableContent) {
      setDisableContent(false);
    } else {
      setDisableContent(true);
    }
  }, [disableContent, setDisableContent]);

  return (
    <StyledWrapper id={id}>
      <BarWrapper>
        <NavWrapper>
          <IconWrapper onClick={toggleContent}>
            <ChevronUpIcon />
          </IconWrapper>
          <Title>{title}</Title>
        </NavWrapper>
        <PerformanceButtonWrapper>{button || button}</PerformanceButtonWrapper>
      </BarWrapper>
      {disableContent || <ContentWrapper>{children}</ContentWrapper>}
      {disableContent || (
        <BottomWrapper>
          {isPredefinedSectionTypes(sectionName) && formShow && (
            <FeedbackForm
              changeFormShow={() => changeFormShow(0, choose, true)}
              openMsgShow={openMsgShow}
              sessionId={sessionId}
              sectionName={sectionName}
              isLike={choose === -1 ? true : false}
            />
          )}
          <ThumbsWrapper
            onClick={() => changeFormShow(-1, choose, false)}
            style={{
              background: choose == -1 ? "rgba(0,0,0,0.4" : "rgba(0,0,0,0.04",
            }}
          >
            <ThumbsUpIcon />
          </ThumbsWrapper>
          <ThumbsWrapper
            onClick={() => changeFormShow(1, choose, false)}
            style={{
              background: choose == 1 ? "rgba(0,0,0,0.4" : "rgba(0,0,0,0.04",
            }}
          >
            <ThumbsDownIcon />
          </ThumbsWrapper>
          {msg && <MsgAlert closeMsgShow={closeMsgShow}></MsgAlert>}
        </BottomWrapper>
      )}
    </StyledWrapper>
  );
};

export default ProductSection;

const StyledWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 60px;
  border-bottom: 1px solid #e5e0e9;

  flex-wrap: wrap;
`;

const BarWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NavWrapper = styled("div")`
  display: flex;
  flex-direction: row;
`;

const PerformanceButtonWrapper = styled("div")``;

const IconWrapper = styled("div")`
  height: 41px;
  margin-right: 30px;

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const ContentWrapper = styled("div")`
  display: flex;
  padding-top: 35px;
  padding-bottom: 60px;
  justify-content: space-between;
`;

const Title = styled.div`
  color: #0d0319;
  font-family: "Noto Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 41px;
`;

const ThumbsWrapper = styled("div")`
  width: 48px;
  height: 48px;
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  background: rgba(0, 0, 0, 0.04);
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`;

const BottomWrapper = styled.div`
  height: 48px;
  display: flex;
  justify-content: flex-end;

  position: relative;
`;
