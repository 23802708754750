import React from "react";
import styled from "styled-components";
import { CommonSectionInfo } from "models/pages/products";
import Markdown from "react-markdown";
import {
  LoadingProductPlaceholder,
  LoadingThumbnailPlaceholder,
} from "./LoadingPlaceHolders";

interface SpecificationSectionProps {
  specifications: CommonSectionInfo[];
  isLoadingThumbnail: boolean;
}

const SpecificationSection: React.FC<SpecificationSectionProps> = ({
  specifications,
  isLoadingThumbnail,
}) => (
  <SepeciticationSectionWrapper>
    {isLoadingThumbnail ? (
      <LoadingThumbnailPlaceholder />
    ) : (
      specifications.map((specification, index) => (
        <SepecificationWrapper key={index}>
          {specification.isLoading ? (
            <LoadingProductPlaceholder />
          ) : (
            <SepecificationContent>
              <Markdown>{specification.content}</Markdown>
            </SepecificationContent>
          )}
        </SepecificationWrapper>
      ))
    )}
  </SepeciticationSectionWrapper>
);

export default SpecificationSection;

const SepeciticationSectionWrapper = styled("div")`
  display: flex;
  align-items: flex-start;
  gap: var(--column-gap, 102px);
  align-self: stretch;
  width: 100%;
`;

const SepecificationWrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

const SepecificationContent = styled("div")`
  width: 100%;
  strong {
    font-weight: normal;
  }
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`;
