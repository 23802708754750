import React from "react";
import styled from "styled-components";
import { CommonSectionInfo } from "models/pages/products";
import TagGroup from "./TagGroup";
import Markdown from "react-markdown";
import {
  LoadingProductPlaceholder,
  LoadingThumbnailPlaceholder,
} from "./LoadingPlaceHolders";

interface CustomSectionProps {
  sectionInfos: CommonSectionInfo[];
  isLoadingThumbnail: boolean;
}

const CustomSection: React.FC<CustomSectionProps> = ({
  sectionInfos,
  isLoadingThumbnail,
}) => {
  return (
    <CustomSectionWrapper>
      {isLoadingThumbnail ? (
        <LoadingThumbnailPlaceholder />
      ) : (
        sectionInfos.map((sortedSectionInfo) => (
          <>
            {sortedSectionInfo.isLoading ? (
              <LoadingProductPlaceholder />
            ) : (
              <SectionWrapper>
                {sortedSectionInfo &&
                  sortedSectionInfo.highlights.length > 0 && (
                    <TagWrapper>
                      <TagGroup tags={sortedSectionInfo.highlights}></TagGroup>
                    </TagWrapper>
                  )}
                {sortedSectionInfo && sortedSectionInfo.content && (
                  <Content>
                    <Markdown>{sortedSectionInfo.content}</Markdown>
                  </Content>
                )}
              </SectionWrapper>
            )}
          </>
        ))
      )}
    </CustomSectionWrapper>
  );
};

export default CustomSection;

const CustomSectionWrapper = styled("div")`
  display: flex;
  align-items: flex-start;
  gap: var(--column-gap, 102px);
  align-self: stretch;
  width: 100%;
`;

const SectionWrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

const TagWrapper = styled("div")`
  width: 100%;
  margin-bottom: 40px;
`;

const Content = styled("div")`
  width: 100%;
  strong {
    font-weight: normal;
  }
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`;
