import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { truncate } from "lodash";
import Rating from "react-rating";

import { StarFilled, StarGray } from "components/icon/Star";
import PlusIcon from "components/icon/PlusIcon";
import CheckedIcon from "components/icon/CheckedIcon";
import loadingspinner from "assets/images/loadingspinner.gif";
import { ChatProductInfo } from "models/services/api";
import { addProduct } from "services/product";

interface ProductSuggestionsProps {
  results: ChatProductInfo[];
  productUrls: string[];
  onAddProduct: () => void;
  sessionId: string;
}

const ProductSuggestions: React.FC<ProductSuggestionsProps> = ({
  results,
  productUrls,
  onAddProduct,
  sessionId,
}) => {
  const [loadingProductUrls, setLoadingProductUrls] = useState<string[]>([]);

  const handleClick = useCallback(
    (productUrl) => {
      setLoadingProductUrls((loadingProductUrls) => [
        ...loadingProductUrls,
        productUrl,
      ]);
      addProduct(sessionId, productUrl).then(() => {
        onAddProduct();
        setLoadingProductUrls((productUrls) =>
          productUrls.filter((url) => url !== productUrl)
        );
      });
    },
    [setLoadingProductUrls]
  );

  return (
    <ProductSuggestionsWrapper>
      {results.map((result, index: number) => (
        <ProductSuggestionWrapper key={index}>
          <ProductSuggestion>
            <ProductIconWrapper>
              <ProductIcon backgroundImage={result.product.image_links[0]} />
            </ProductIconWrapper>
            <ProductInfoWrapper>
              <ProductTitleWrapper>
                {truncate(result.product.name, { length: 50 })}
              </ProductTitleWrapper>
              <ProductFooterWrapper>
                <ProductInfo>
                  <ProductRating>
                    <Rating
                      initialRating={result.product.rating}
                      readonly
                      emptySymbol={<StarGray />}
                      fullSymbol={<StarFilled />}
                    />
                  </ProductRating>
                  <ProductPrice>
                    {`From ${result.product.typical_prices.low_price}`}
                  </ProductPrice>
                </ProductInfo>

                {loadingProductUrls.includes(result.product.link) ? (
                  <ProductSuggestionsCTA status="loading">
                    <ItemLoadingSpinner src={loadingspinner} />
                  </ProductSuggestionsCTA>
                ) : productUrls.includes(result.product.link) ? (
                  <ProductSuggestionsCTA status="added">
                    <CheckedIcon />
                    <ProductSuggestionsCTAText>Added</ProductSuggestionsCTAText>
                  </ProductSuggestionsCTA>
                ) : (
                  <ProductSuggestionsCTA
                    status="new"
                    onClick={() => handleClick(result.product.link)}
                  >
                    <PlusIcon />
                    <ProductSuggestionsCTAText>
                      Compare
                    </ProductSuggestionsCTAText>
                  </ProductSuggestionsCTA>
                )}
              </ProductFooterWrapper>
            </ProductInfoWrapper>
          </ProductSuggestion>
        </ProductSuggestionWrapper>
      ))}
    </ProductSuggestionsWrapper>
  );
};

export default ProductSuggestions;

const ProductSuggestionWrapper = styled.div<
  React.AllHTMLAttributes<HTMLDivElement>
>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const ProductSuggestion = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const ProductIconWrapper = styled.div`
  display: flex;
  width: 46px;
  height: 46px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--purple-gray20, #e5e0e9);
  background: var(--white, #fff);
`;

interface ProductIconProps extends React.AllHTMLAttributes<HTMLDivElement> {
  backgroundImage: string;
}

const ProductIcon = styled.div<ProductIconProps>`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  background-image: url(${(props) => props.backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const ProductInfoWrapper = styled.div`
  display: flex;
  height: 80px;
  width: 120px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

const ProductTitleWrapper = styled.div`
  flex-shrink: 0;
  align-self: stretch;
  overflow: hidden;
  color: var(--black, #0d0319);
  text-overflow: ellipsis;
  white-space: break-spaces;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
`;

const ProductFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
`;

const ProductInfo = styled.div`
  display: flex;
  gap: 8px;
`;

const ProductRating = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  span {
    height: 18px;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const ProductPrice = styled.div`
  color: var(--purple-gray70, #74697b);

  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const ProductSuggestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

interface ProductSuggestionsCTAProps
  extends React.AllHTMLAttributes<HTMLDivElement> {
  status: "added" | "loading" | "new";
}

const ProductSuggestionsCTA = styled.div<ProductSuggestionsCTAProps>`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: ${(props) =>
    props.status && ["added", "loading"].includes(props.status)
      ? "auto"
      : "pointer"};

  &:hover {
    opacity: ${(props) =>
      props.status && ["added", "loading"].includes(props.status) ? 0.6 : 0.9};
  }

  &:active {
    opacity: ${(props) =>
      props.status && ["added", "loading"].includes(props.status) ? 0.6 : 0.8};
  }

  opacity: ${(props) =>
    props.status && ["added", "loading"].includes(props.status) ? 0.6 : 1};

  svg {
    width: 18px;
  }

  path {
    stroke: #8319f5;
  }
`;

const ProductSuggestionsCTAText = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  background: var(
    --brand,
    linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ItemLoadingSpinner = styled.img<
  React.AllHTMLAttributes<HTMLImageElement>
>`
  height: 18px;
  width: 18px;
`;
