import React from "react";

const AngleDownIcon: React.FC = () => {
  return (
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="u:angle-down">
              <path id="Vector"
                    d="M16.9999 9.80422C16.8126 9.61797 16.5591 9.51343 16.2949 9.51343C16.0308 9.51343 15.7773 9.61797 15.5899 9.80422L11.9999 13.3442L8.45995 9.80422C8.27259 9.61797 8.01913 9.51343 7.75495 9.51343C7.49076 9.51343 7.23731 9.61797 7.04995 9.80422C6.95622 9.89718 6.88183 10.0078 6.83106 10.1296C6.78029 10.2515 6.75415 10.3822 6.75415 10.5142C6.75415 10.6462 6.78029 10.7769 6.83106 10.8988C6.88183 11.0207 6.95622 11.1313 7.04995 11.2242L11.2899 15.4642C11.3829 15.5579 11.4935 15.6323 11.6154 15.6831C11.7372 15.7339 11.8679 15.76 11.9999 15.76C12.132 15.76 12.2627 15.7339 12.3845 15.6831C12.5064 15.6323 12.617 15.5579 12.7099 15.4642L16.9999 11.2242C17.0937 11.1313 17.1681 11.0207 17.2188 10.8988C17.2696 10.7769 17.2957 10.6462 17.2957 10.5142C17.2957 10.3822 17.2696 10.2515 17.2188 10.1296C17.1681 10.0078 17.0937 9.89718 16.9999 9.80422Z"
                    fill="#0D0319"/>
          </g>
      </svg>
  );
};
export default AngleDownIcon;
